<ng-container *ngIf="content">
  <div class="common-content" [innerHTML]="content"></div>
</ng-container>

<ng-container *ngIf="expandableContent">
  <div cdkAccordion>
    <div cdkAccordionItem [expanded]="false" #expandableContentElement
      #expandableContentItem="cdkAccordionItem">
      <a class="details-toggle-btn" [ngClass]="{
        'details-toggle-btn_expanded': expandableContentItem.expanded,
        'details-toggle-btn_collapsed': !expandableContentItem.expanded
      }" (click)="expandableContentItem.toggle()">
        <ng-container *ngIf="expandableContentItem.expanded; else collapsed">
          {{ 'Toasts.Template.ExpandableContent.HideDetails' | translate }}
        </ng-container>

        <ng-template #collapsed>
          {{ 'Toasts.Template.ExpandableContent.ShowDetails' | translate }}
        </ng-template>

        <heroicon icon="chevron-down" type="solid" [@rotate]="expandableContentItem.expanded ? '1' : '0'">
        </heroicon>
      </a>

      <ng-container *ngIf="expandableContentItem.expanded">
        <div class="expandable-content" [style.width.px]="maxWidth" [@expandOnEnter] [@collapseOnLeave]>
          <pre>{{ expandableContent }}</pre>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
