import { DOCUMENT, Location } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { MatchingResources, ProtectedResourceScopes } from '@azure/msal-angular/msal.interceptor.config';
import { AccountInfo, AuthenticationResult, StringUtils, UrlString } from '@azure/msal-browser';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '@WebUi/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from '@Libs/toasts';
import { ToastSimpleContentComponent } from '@WebUi/shared/components/toast-templates/simple-content/simple-content.component';
import { IUri } from '@azure/msal-common';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationInterceptor implements HttpInterceptor {

  private readonly stopAuthorizedRequests$$: Subject<void> = new Subject<void>();
  readonly stopAuthorizedRequests$: Observable<void> = this.stopAuthorizedRequests$$.asObservable();

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private msalService: MsalService,
    private location: Location,
    private translateService: TranslateService,
    private toastsService: ToastsService,
  ) { }

  // eslint-disable-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const scopes: string[] | null = this.getScopesForEndpoint(request.url, request.method);

    // If no scopes for endpoint, does not acquire token
    if (!scopes || scopes.length === 0) {
      return next.handle(request)
        .pipe(
          catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
        );
    }

    const account: AccountInfo | null = this.msalService.instance.getActiveAccount();

    if (!account) {
      return next.handle(request)
        .pipe(
          catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
        );
    }

    return this.authService.acquireTokenSilent({
      scopes,
    })
      .pipe(
        switchMap((authenticationResult: AuthenticationResult) => {
          const modifiedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${authenticationResult.accessToken}`),
          });

          return next.handle(modifiedRequest)
            .pipe(
              takeUntil(this.stopAuthorizedRequests$),
              catchError((response: HttpErrorResponse) => this.handleUnauthorizedResponseError(response)),
            );
        }),
      );
  }

  private handleUnauthorizedResponseError(response: HttpErrorResponse): Observable<never> {
    if (response.status === 401) {
      this.translateService.get('Toasts.HttpErrorResponse.401')
        .subscribe((translation: any) => {
          this.toastsService.error({
            heading: translation['Heading'],
            contentFactory: {
              component: ToastSimpleContentComponent,
              properties: {
                content: translation['Description'],
              },
            },
            autoclose: false,
          });
        });

      this.stopAuthorizedRequests$$.next();

      this.authService.logout()
        .subscribe();

      // Stop propogating error response, just wait for logout redirect
      return EMPTY;
    }

    return throwError(() => response);
  }


  /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param httpMethod Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  private getScopesForEndpoint(
    endpoint: string,
    httpMethod: string
  ): Array<string> | null {
    this.msalService
      .getLogger()
      .verbose('Interceptor - getting scopes for endpoint');

    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint = this.location.normalize(endpoint);

    const protectedResourcesArray = Array.from(
      this.msalInterceptorConfig.protectedResourceMap.keys()
    );

    const matchingProtectedResources = this.matchResourcesToEndpoint(
      protectedResourcesArray,
      normalizedEndpoint
    );

    // Check absolute urls of resources first before checking relative to prevent incorrect matching where multiple resources have similar relative urls
    if (matchingProtectedResources.absoluteResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.absoluteResources,
        httpMethod
      );
    } else if (matchingProtectedResources.relativeResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.relativeResources,
        httpMethod
      );
    }

    return null;
  }

  /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesEndpoints
   * @param endpoint
   * @returns
   */
  private matchResourcesToEndpoint(
    protectedResourcesEndpoints: string[],
    endpoint: string
  ): MatchingResources {
    const matchingResources: MatchingResources = {
      absoluteResources: [],
      relativeResources: [],
    };

    protectedResourcesEndpoints.forEach((key) => {
      // Normalizes and adds resource to matchingResources.absoluteResources if key matches endpoint. StringUtils.matchPattern accounts for wildcards
      const normalizedKey = this.location.normalize(key);

      if (StringUtils.matchPattern(normalizedKey, endpoint)) {
        matchingResources.absoluteResources.push(key);
      }

      // Get url components for relative urls
      const absoluteKey: string = this.getAbsoluteUrl(key);
      const keyComponents: IUri = new UrlString(absoluteKey).getUrlComponents();
      const absoluteEndpoint: string = this.getAbsoluteUrl(endpoint);
      const endpointComponents: IUri = new UrlString(absoluteEndpoint).getUrlComponents();

      // Normalized key should include query strings if applicable
      const relativeNormalizedKey: string = keyComponents.QueryString
        ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}`
        : this.location.normalize(keyComponents.AbsolutePath);

      // Add resource to matchingResources.relativeResources if same origin, relativeKey matches endpoint, and is not empty
      if (
        keyComponents.HostNameAndPort === endpointComponents.HostNameAndPort &&
        StringUtils.matchPattern(relativeNormalizedKey, absoluteEndpoint) &&
        relativeNormalizedKey !== '' &&
        relativeNormalizedKey !== '/*'
      ) {
        matchingResources.relativeResources.push(key);
      }
    });

    return matchingResources;
  }

  /**
   * Transforms relative urls to absolute urls
   * @param url
   * @returns
   */
  private getAbsoluteUrl(url: string): string {
    const link: HTMLAnchorElement = this.document.createElement('a');

    link.href = url;

    return link.href;
  }

  /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
  private matchScopesToEndpoint(
    protectedResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null>,
    endpointArray: string[],
    httpMethod: string,
  ): Array<string> | null {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    const allMatchedScopes: any[] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const scopesForEndpoint: any[] = [];
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);

      // Return if resource is unprotected
      if (!methodAndScopesArray) {
        allMatchedScopes.push(null);

        return;
      }

      methodAndScopesArray.forEach((entry: string | ProtectedResourceScopes) => {
        // Entry is either array of scopes or ProtectedResourceScopes object
        if (typeof entry === 'string') {
          scopesForEndpoint.push(entry);
        } else {
          // Ensure methods being compared are normalized
          const normalizedRequestMethod: string = httpMethod.toLowerCase();
          const normalizedResourceMethod: string = entry.httpMethod.toLowerCase();
          // Method in protectedResourceMap matches request http method
          if (normalizedResourceMethod === normalizedRequestMethod) {
            // Validate if scopes comes null to unprotect the resource in a certain http method
            if (entry.scopes === null) {
              allMatchedScopes.push(null);
            } else {
              entry.scopes.forEach((scope: string) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      });

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length > 0) {
      if (allMatchedScopes.length > 1) {
        this.msalService
          .getLogger()
          .warning(
            'Interceptor - More than 1 matching scopes for endpoint found.'
          );
      }

      // Returns scopes for first matching endpoint
      return allMatchedScopes[0];
    }

    return null;
  }

}
