import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { BlockingLoaderService } from '@WebUi/app/services/blocking-loader.service';
import { ShopifyService } from '@WebUi/app/services/shopify.service';
import { ConnectionsService } from '@WebUi/connections/services/connections.service';
import { CompanyConnectionCreatedFeedback } from '@WebUi/dashboard/store/dashboard.actions';
import {
  AccountingSystem,
  Agreement,
  CompanyConnection,
  ExternalSystemKind,
  ExternalSystemType,
  ExternalSystemTypeFeature,
  isAccountingSystem,
  SalesChannel,
  UserCompany,
} from '@Libs/model';
import { Store } from '@ngxs/store';
import { OnboardingService } from '@WebUi/onboarding/services/onboarding.service';
import { ResetOnboarding, StoreOnboardingInput } from '@WebUi/onboarding/store/onboarding.actions';
import { Observable, combineLatest, iif, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ExternalSystemToConnect } from '@WebUi/connections/models/connections.model';
import { ResetExternalSystemToConnect } from '@WebUi/connections/store/connections.actions';
import { PrepareSetupWizardStateWithOnboardingSnapshot } from '@WebUi/setup-wizard/store/setup-wizard.actions';
import { OnboardingFlowState, OnboardingOption, OnboardingShopifyToken } from '@WebUi/onboarding/models/onboarding.model';
import { BillingApiService } from '@WebUi/dashboard/services/billing-api.service';
import { CompaniesService } from '@WebUi/dashboard/services/companies.service';
import { DashboardService } from '@WebUi/dashboard/services/dashboard.service';
import { SuService } from '@WebUi/su/services/su.service';
import { PermissionsService } from '@WebUi/dashboard/services/permissions.service';

enum ShopifyInstallationMode {
  OLD = 0,
  NEW = 1,
}

type ShopifyHandlerToken = {
  mode: ShopifyInstallationMode;
  token: OnboardingShopifyToken;
};

@Injectable({
  providedIn: 'root',
})
export class ShopifyConnectionTokenHandlerGuard implements CanActivate {

  constructor(
    private onboardingService: OnboardingService,
    private connectionsService: ConnectionsService,
    private billingApiService: BillingApiService,
    private presmissionsService: PermissionsService,
    private companiesService: CompaniesService,
    private dashboardService: DashboardService,
    private suService: SuService,
    private router: Router,
    private store: Store,
    private shopifyService: ShopifyService,
    private blockingLoaderService: BlockingLoaderService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // We got token param if user setup was initiated by new Shopify service
    const tokenParam: string | null = route.queryParamMap.get('token');
    // We got connectionToken param if user setup was initiated by old Shopify service
    const connectionTokenParam: string | null = route.queryParamMap.get('connectionToken');
    const shopParam: string | null = route.queryParamMap.get('shop');

    let shopifyHandlerToken: ShopifyHandlerToken | null = null;

    if (!shopParam) {
      return of(this.router.parseUrl('/dashboard'));
    }

    if (tokenParam) {
      shopifyHandlerToken = {
        mode: ShopifyInstallationMode.NEW,
        token: {
          connectionToken: tokenParam,
          shop: shopParam,
        },
      };
    } else if (connectionTokenParam) {
      shopifyHandlerToken = {
        mode: ShopifyInstallationMode.OLD,
        token: {
          connectionToken: connectionTokenParam,
          shop: shopParam,
        },
      };
    }

    if (!shopifyHandlerToken) {
      return of(this.router.parseUrl('/dashboard'));
    }

    return combineLatest([
      this.onboardingService.flowState$,
      this.onboardingService.company$,
      this.onboardingService.accountingSystemConnection$,
      this.connectionsService.externalSystemToConnect$,
    ])
      .pipe(
        take(1),
        switchMap(([flowState, onboardingCompany, accountingSystemConnection, externalSystemToConnect]: [OnboardingFlowState | null, UserCompany | null, CompanyConnection | null, ExternalSystemToConnect | null]) => {
          // User initiated Shopify installation from Onboarding section
          if (flowState && flowState.option === OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE && onboardingCompany && accountingSystemConnection) {
            this.blockingLoaderService.start();

            return this.connectionsService.unifiedCompleteConnection(
              () => {
                switch (shopifyHandlerToken!.mode) {
                  case ShopifyInstallationMode.OLD:
                    return this.shopifyService.completeCompanyConnection(
                      onboardingCompany,
                      shopifyHandlerToken!.token,
                    );
                  case ShopifyInstallationMode.NEW:
                    return this.shopifyService.createCompanyConnection(
                      onboardingCompany,
                      shopifyHandlerToken!.token,
                    );
                }
              }
            )
              .pipe(
                switchMap((newConnectionContext: CompanyConnectionCreatedFeedback) => {
                  return this.billingApiService.postCompanyAgreement(newConnectionContext.companyId, {
                    agreementId: flowState.agreementId,
                    connections: [{
                      systemId: newConnectionContext.companyConnection.externalSystemId,
                      systemType: newConnectionContext.companyConnection.externalSystemTypeId,
                    }],
                  })
                    .pipe(
                      map(() => newConnectionContext),
                    );
                }),
                switchMap((newConnectionContext: CompanyConnectionCreatedFeedback) => this.store.dispatch([new PrepareSetupWizardStateWithOnboardingSnapshot({
                  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE,
                  feature: flowState.feature,
                  agreementId: flowState.agreementId,
                  companyId: newConnectionContext.companyId,
                  accountingSystem: accountingSystemConnection.externalSystemTypeId as AccountingSystem,
                  accountingSystemConnectionId: accountingSystemConnection.id,
                  salesChannel: SalesChannel.SHOPIFY,
                  salesChannelConnectionId: newConnectionContext.companyConnection.id,
                  hasEstore: true,
                })])),
                switchMap(() => this.store.dispatch([new ResetOnboarding()])),
                map(() => this.router.parseUrl('/setup-wizard')),
                catchError(() => {
                  return this.store.dispatch([new ResetOnboarding()])
                    .pipe(
                      map(() => this.router.parseUrl('/dashboard')),
                    );
                }),
                finalize(() => {
                  this.blockingLoaderService.stop();
                }),
              );
          }

          // User initiated Shopify installation from Connections section
          if (externalSystemToConnect && externalSystemToConnect.externalSystem === SalesChannel.SHOPIFY) {
            this.blockingLoaderService.start();

            return this.connectionsService.unifiedCompleteConnection(
              () => {
                switch (shopifyHandlerToken!.mode) {
                  case ShopifyInstallationMode.OLD:
                    return this.shopifyService.completeCompanyConnection(
                      externalSystemToConnect.company,
                      shopifyHandlerToken!.token,
                    );
                  case ShopifyInstallationMode.NEW:
                    return this.shopifyService.createCompanyConnection(
                      externalSystemToConnect.company,
                      shopifyHandlerToken!.token,
                    );
                }
              }
            )
              .pipe(
                withLatestFrom(
                  iif(
                    () => this.presmissionsService.canUseSuModule(),
                    this.suService.getUserCompanyByCompanyId$(externalSystemToConnect.company.id),
                    this.companiesService.getUserCompanyByCompanyId$(externalSystemToConnect.company.id),
                  ),
                  this.dashboardService.externalSystemTypesByKind$
                    .pipe(
                      map((filterFn) => filterFn(ExternalSystemKind.ACCOUNTING_SYSTEM)),
                    ),
                ),
                take(1),
                switchMap(([newConnectionContext, company, accountingSystems]: [CompanyConnectionCreatedFeedback, UserCompany | undefined, ExternalSystemType[] | null]) => {
                  const externalSystemTypes: string[] = [SalesChannel.SHOPIFY];

                  if (company && accountingSystems) {
                    const accountingSystem: CompanyConnection | undefined = company?.connections.find((connection: CompanyConnection) => isAccountingSystem(accountingSystems, connection.externalSystemTypeId));

                    if (accountingSystem) {
                      externalSystemTypes.push(accountingSystem.externalSystemTypeId);
                    }
                  }

                  return this.billingApiService.fetchAgreementByConstrains(ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING, externalSystemTypes.join(','))
                    .pipe(
                      switchMap((agreement: Agreement) => {
                        return this.billingApiService.postCompanyAgreement(newConnectionContext.companyId, {
                          agreementId: agreement.id,
                          connections: [{
                            systemId: newConnectionContext.companyConnection.externalSystemId,
                            systemType: newConnectionContext.companyConnection.externalSystemTypeId,
                          }],
                        });
                      }),
                      map(() => newConnectionContext),
                      catchError(() => of(newConnectionContext)),
                    );
                }),
                map((newConnectionContext: CompanyConnectionCreatedFeedback) => this.router.parseUrl(`/connections/${encodeURIComponent(newConnectionContext.companyConnection.id)}/configuration`)),
                catchError(() => of(this.router.parseUrl('/connections'))),
                finalize(() => {
                  this.store.dispatch([new ResetExternalSystemToConnect()]);

                  this.blockingLoaderService.stop();
                }),
              );
          }

          // User manually installed shopify application
          // Remember shopify connection token and start onboarding
          return this.store.dispatch([new ResetOnboarding()])
            .pipe(
              switchMap(() => this.store.dispatch([new StoreOnboardingInput({
                option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE,
                feature: ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING,
                agreementId: null,
                accountingSystem: null,
                salesChannel: SalesChannel.SHOPIFY,
                shopifyToken: shopifyHandlerToken!.token,
                hasEstore: true,
                paymentProvider: null,
              })])),
              map(() => this.router.parseUrl('/onboarding/init')),
            );
        }),
      );
  }

}
