import { ErrorHandler, Injectable } from '@angular/core';
import { RollbarService } from '@WebUi/app/services/rollbar.service';
import { environment } from '@WebUi/env';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(private rollbarService: RollbarService) { }

  handleError(error: Error): void {
    if (!environment.logging.rollbar) {
      return;
    }

    this.rollbarService.error(error);
  }

}
