export enum UnimicroPlatformApiErrorCode {
  UNKNOWN = 'Ss0',
  MISSING_COMPANY_ID_HEADER = 'Ss1',
  CORRUPTED_PAYLOAD = 'Ss2',
  ACCOUNT_NUMBER_OUT_OF_RANGE = 'Ss3',
  ACCOUNT_NUMBER_EXISTS = 'Ss4',
  INVALID_GROUP_NUMBER = 'Ss5',
  MISSING_COMPANY_KEY_PARAMETER = 'Ss6',
  MISSING_BEARER_AUTHORIZATION_TOKEN = 'Ss7',
  INVALID_BEARER_AUTHORIZATION_TOKEN = 'Ss8',
  UNKNOWN_PLATFORM_CODE = 'Ss9',
  COMPANY_ALREADY_CONNECTED_TO_PLATFORM = 'Ss10',
  INSUFFICIENT_PLATFORM_USER_RIGHTS = 'Ss11',
};

export const UNIMICRO_PLATFORM_API_ERROR_CODES: UnimicroPlatformApiErrorCode[] = Object.values(UnimicroPlatformApiErrorCode);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUnimicroPlatformApiError(error: any): error is UnimicroPlatformApiErrorResponse {
  return Object.hasOwn(error, 'errorCode') && Object.hasOwn(error, 'errorMessage') && isUnimicroPlatformApiErrorCode(error.errorCode);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUnimicroPlatformApiErrorCode(errorCode: any): errorCode is UnimicroPlatformApiErrorCode {
  return UNIMICRO_PLATFORM_API_ERROR_CODES.includes(errorCode);
}

export type UnimicroPlatformApiErrorResponse = {
  errorCode: UnimicroPlatformApiErrorCode;
  errorMessage: string;
};
